import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

/**
 * Textures
 */

// const image = new Image()
// const texture = new THREE.Texture(image)

// image.onload = () =>
// {
//     texture.needsUpdate = true
// }

// image.src = '/textures/matcaps/BFB5A4_DEDCCB_D7D4CC_DCD3C2.png'
// image.src = '/textures/matcaps/C1AA92_AD6E29_737889_CED1D7.png'
// image.src = '/textures/matcaps/B0A2A8_866A63_E8E9F2_614C4F.png'

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(5, 5),
    new THREE.ShadowMaterial({
        color: 0,
        opacity: 0.3,
        transparent: true,
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)

/**
 * Models
 */


const gltfLoader = new GLTFLoader()

let mixer = null

gltfLoader.load(
    '/models/SC/glTF/out.gltf',
    (gltf) =>
    {

        gltf.scene.traverse(function (child) {

            if (child.isMesh) {

                child.castShadow = true;

                child.receiveShadow = true;
                child.material = new THREE.MeshBasicMaterial({
                    vertexColors: true,
                    // matcap: texture
                })

            }
        })
        
        
        mixer = new THREE.AnimationMixer(gltf.scene)
        const action = mixer.clipAction(gltf.animations[0])

        action.play()

        console.log(action)

        gltf.scene.scale.set(1, 1, 1)
        scene.add(gltf.scene)
       
    }
)

/**
 * Lights
 */

const directionalLight = new THREE.SpotLight(0xffffff, 1)
directionalLight.castShadow = true
directionalLight.angle = .2
directionalLight.position.set(0, 10, 2)
scene.add(directionalLight)



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(8 , 3, -1.5)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 1, 0.1)
controls.enableDamping = true
controls.autoRotate = true
controls.autoRotateSpeed = 0.1

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setClearColor(0x222222, 1);
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update mixer
    if(mixer !== null)
    {
        mixer.update(deltaTime)
    }


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()